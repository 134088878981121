<template>
  <h1>Emails</h1>
</template>

<script>
export default {
  name: 'Emails',
};
</script>

<style>

</style>
