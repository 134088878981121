<template>
  <div class="emails">
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="emails__controls">
            <b-button
              size="sm"
              variant="success"
            >
              Создать
            </b-button>

            <div>
              <b-button
                v-if="!message.text && page > 0"
                class="mr-2"
                size="sm"
                variant="warning"
                @click="scrollToTop"
              >
                Наверх
              </b-button>
              <b-button
                :disabled="showFilter"
                size="sm"
                variant="light"
                @click="showFilter = true"
              >
                Фильтр
              </b-button>
            </div>
          </div>

          <div v-if="loading">
            <Spinner />
          </div>

          <template v-else>
            <b-alert
              :show="Boolean(message.text)"
              variant="secondary"
            >
              <p class="mb-0">
                <span
                  class="font-weight-bold"
                  :style="{
                    color: message.isError ? '#A94442' : '#0088FF'
                  }"
                >
                  {{ message.header }}
                </span>
                <span>{{ message.text }}</span>
              </p>
            </b-alert>

            <b-table
              v-if="tableItems.length && !message.text"
              ref="table"
              class="mb-0"
              :class="{'filter-open': showFilter}"
              :fields="headers"
              fixed
              :items="tableItems"
              small
              sticky-header="calc(100vh - 40px - 64px)"
              :style="{
                marginRight: '-10px',
                paddingRight: '10px'
              }"
              :tbody-tr-class="rowClass"
              @row-clicked="selectRow"
            >
              <template #head(name)="data">
                <div class="d-flex align-items-center">
                  <span>{{ data.label }}</span>
                  <div class="d-inline-block ml-1">
                    <div
                      class="icon-asc"
                      :class="{'active': sorting === SORTING_OPTIONS.NAME_ASC}"
                      @click="setSorting(SORTING_OPTIONS.NAME_ASC)"
                    />
                    <div
                      class="icon-desc"
                      :class="{'active': sorting === SORTING_OPTIONS.NAME_DESC}"
                      @click="setSorting(SORTING_OPTIONS.NAME_DESC)"
                    />
                  </div>
                </div>
              </template>

              <template #head(created)="data">
                <div class="d-flex align-items-center">
                  <span>{{ data.label }}</span>
                  <div class="d-inline-block ml-1">
                    <div
                      class="icon-asc"
                      :class="{'active': sorting === SORTING_OPTIONS.CREATED_ASC}"
                      @click="setSorting(SORTING_OPTIONS.CREATED_ASC)"
                    />
                    <div
                      class="icon-desc"
                      :class="{'active': sorting === SORTING_OPTIONS.CREATED_DESC}"
                      @click="setSorting(SORTING_OPTIONS.CREATED_DESC)"
                    />
                  </div>
                </div>
              </template>

              <template #bottom-row="{ columns }">
                <td :colspan="columns">
                  <Observer
                    v-show="hasMoreItems"
                    ref="observer"
                    @intersect="onIntersect"
                  >
                    <Spinner />
                  </Observer>
                </td>
              </template>
            </b-table>
          </template>
        </b-col>
      </b-row>
    </b-container>

    <filter-panel
      v-show="showFilter"
      @clear="onFilterClear"
      @close="onFilterClose"
      @submit="onFilterSubmit"
    >
      <template #content>
        <div class="mb-3">
          <div class="d-flex align-items-center mb-1">
            <p class="font-weight-bold mb-0 mr-1">
              Наименование шаблона
            </p>
            <tooltip
              text="До 5-ти слов, состоящих до 20-ти букв любого алфавита и цифр."
            />
          </div>
          <b-form-input
            v-model="filter.name"
            :placeholder="defaultPlaceholder"
            size="sm"
          />
        </div>

        <div class="mb-3">
          <div class="d-flex align-items-center mb-1">
            <p class="font-weight-bold mb-0 mr-1">
              Тема письма
            </p>
            <tooltip
              text="До 5-ти слов, состоящих до 20-ти букв любого алфавита и цифр."
            />
          </div>
          <b-form-input
            v-model="filter.subject"
            :placeholder="defaultPlaceholder"
            size="sm"
          />
        </div>

        <p class="font-weight-bold mb-2">
          Отправитель
        </p>

        <div class="mb-3">
          <p class="mb-1">
            Адрес электронной почты
          </p>
          <b-form-input
            v-model="filter.email"
            :placeholder="defaultPlaceholder"
            size="sm"
          />
        </div>

        <div class="mb-3">
          <div class="d-flex align-items-center mb-1">
            <p class="mb-0 mr-1">
              Отправитель
            </p>
            <tooltip
              text="От 2-х до 20-ти букв любого алфавита и цифр."
            />
          </div>
          <b-form-input
            v-model="filter.from"
            :placeholder="defaultPlaceholder"
            size="sm"
          />
        </div>
      </template>
    </filter-panel>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import {
  compareAsc,
  compareDesc,
} from 'date-fns';
import { TemplateActionTypes } from '@/store';
import {
  FilterPanel,
  Observer,
  Spinner,
  Tooltip,
} from '@/components';
import { formatDate } from '@/filters';
import {
  MAX_LIMIT,
  SORTING_OPTIONS,
} from '@/constants';

export default {
  name: 'Templates',
  components: {
    FilterPanel,
    Observer,
    Spinner,
    Tooltip,
  },
  constants: {
    MAX_LIMIT,
    SORTING_OPTIONS,
  },
  data() {
    return {
      defaultPlaceholder: 'Не имеет значения',
      filter: {
        name: '',
        subject: '',
        email: '',
        from: '',
      },
      headers: [
        {
          key: 'name',
          label: 'Наименование',
        },
        {
          key: 'subject',
          label: 'Тема письма',
        },
        {
          key: 'values',
          label: 'Параметры',
          thClass: 'header-width-90',
          tdClass(value) {
            if (value === 'Нет') {
              return 'text-secondary';
            }
          },
        },
        {
          key: 'created',
          label: 'Создан',
          thClass: 'header-width-140',
        },
        {
          key: 'updated',
          label: 'Изменен',
          thClass: 'header-width-140',
          tdClass(value) {
            if (value === 'Нет') {
              return 'text-secondary';
            }
          },
        },
      ],
      message: {
        isError: false,
        header: '',
        text: '',
      },
      page: 0,
      search: '',
      selectedRowId: null,
      showFilter: false,
      sorting: SORTING_OPTIONS.CREATED_DESC,
    };
  },
  computed: {
    ...mapGetters('emails/templates', [
      'hasMoreItems',
      'loading',
      'templates',
    ]),

    tableItems() {
      if (!this.templates) {
        return [];
      }

      return this.templates
        .map(({ attributes, id, meta }) => ({
          id,
          name: attributes.name,
          subject: attributes.subject,
          values: attributes.values ? 'Есть' : 'Нет',
          created: formatDate(new Date(meta.created.time), 'dd.MM.yyyy HH:mm'),
          createdTime: meta.created.time,
          updated: meta.updated
            ? formatDate(new Date(meta.updated.time), 'dd.MM.yyyy HH:mm')
            : 'Нет',
        }))
        .sort((a, b) => {
          switch (this.sorting) {
            case SORTING_OPTIONS.NAME_DESC:
              return a.name.localeCompare(b.name);
            case SORTING_OPTIONS.NAME_ASC:
              return a.name.localeCompare(b.name) * -1;
            case SORTING_OPTIONS.CREATED_DESC:
              return compareDesc(new Date(a.createdTime), new Date(b.createdTime));
            case SORTING_OPTIONS.CREATED_ASC:
              return compareAsc(new Date(a.createdTime), new Date(b.createdTime));
            default:
              return 0;
          }
        });
    },
  },
  mounted() {
    this.onIntersect();
  },
  methods: {
    ...mapActions('emails/templates', {
      getTemplates: TemplateActionTypes.GET_TEMPLATES,
      resetTemplates: TemplateActionTypes.RESET_TEMPLATES,
      setLoading: TemplateActionTypes.SET_LOADING,
    }),

    handleError(status) {
      if (this.loading) {
        this.setLoading(false);
      }

      if (status === 404) {
        this.message = {
          isError: false,
          header: 'Не найдено!',
          text: 'Для заданных условий поиска не найден ни один документ.',
        };
      } else {
        this.message = {
          isError: true,
          header: 'Ошибка!',
          text: 'Проблема с сервисом управления документами.',
        };
      }
    },

    checkObserverIsVisible() {
      return this.$refs.observer.$el?.getBoundingClientRect().top <= window.innerHeight;
    },

    onFilterClear() {
      Object.keys(this.filter).forEach(key => {
        this.filter[key] = '';
      });
    },

    onFilterClose() {
      this.showFilter = false;
      this.onFilterClear();
    },

    onFilterSubmit() {
      this.onFilterClose();
    },

    async onIntersect() {
      try {
        await this.getTemplates({ page: this.page });

        if (!this.templates.length && !this.loading) {
          this.message = {
            isError: false,
            header: 'Пусто!',
            text: 'Добавьте документы для работы с ними.',
          };
          return;
        }

        if (this.hasMoreItems) {
          this.page++;

          if (this.checkObserverIsVisible()) {
            await this.onIntersect();
          }
        }
      } catch (error) {
        this.handleError(error?.response?.status);
      }
    },

    removeTemplatesFromList() {
      if (this.templates.length > MAX_LIMIT) {
        this.resetTemplates();
        this.page = 1;
      }
    },

    rowClass(row) {
      return row?.id === this.selectedRowId
        ? 'selected'
        : '';
    },

    scrollToTop() {
      if (!this.$refs.table) {
        return;
      }

      const { $el } = this.$refs.table;
      $el.scrollTo(0, 0);

      this.removeTemplatesFromList();
    },

    selectRow({ id }) {
      console.log({
        selectedRowId: this.selectedRowId,
        id,
      });

      this.selectedRowId = id === this.selectedRowId ? null : id;
    },

    setSorting(sorting) {
      this.sorting = sorting;
    },
  },
};
</script>

<style lang="scss">
  .emails {
    display: flex;
    font-size: 14px;
    margin: 0 auto;
    //max-width: 1310px;
    min-width: 320px;
    position: relative;
    width: 100%;

    &__controls {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
    }

    .b-table {
      th {
        color: #999 !important;
        font-weight: normal;
      }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      th,
      td {
        display: none;

        &[aria-colindex="1"],
        &[aria-colindex="4"] {
          display: table-cell;
        }

        @media (min-width: 640px) {
          display: table-cell;
        }
      }

      tbody tr {
        cursor: pointer;

        &:hover,
        &.selected {
          color: #212529;
          background-color: rgba(0, 0, 0, 0.075);
        }

        &.selected:hover {
          background-color: rgba(0, 0, 0, 0.15);
        }

        &:first-child {
          td {
            border-top: none;
          }
        }
      }

      .icon-asc,
      .icon-desc {
        cursor: pointer;
        font-size: 11px;
        height: 8px;
        text-align: center;

        &:hover,
        &.active {
          color: #000;
        }
      }

      .table-b-table-default > div {
        position: relative;

        &:after {
          background-color: #dee2e6;
          bottom: -0.3rem;
          content: '';
          display: block;
          height: 1px;
          left: -0.3rem;
          position: absolute;
          right: -0.3rem;
        }
      }

      &-sticky-header {

        & > .table.b-table > thead > tr > th {
          border-top: none;
          border-bottom: none;
        }

        &.filter-open {
          th,
          td {
            @media (max-width: 999px) {
              display: none;

              &[aria-colindex="1"],
              &[aria-colindex="4"] {
                display: table-cell;
              }
            }
          }
        }
      }
    }

    .header-width-90 {
      width: 90px;
    }

    .header-width-140 {
      width: 140px;
    }
  }
</style>
