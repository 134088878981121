<template>
  <div
    class="spinner"
    :style="{
      height: size + 'px',
      width: size + 'px'
    }"
  >
    <div
      v-for="i in 12"
      :key="i"
      :class="'bar' + i"
      :style="{
        backgroundColor: color
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: {
      type: String,
      default: '#999',
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: relative;
  display: inline-block;

  div {
    animation: fade 1s linear infinite;
    border-radius: 50px;
    height: 16%;
    left: 49%;
    opacity: 0;
    position: absolute;
    top: 43%;
    width: 6%;

    &.bar1 {
      animation-delay: 0s;
      transform: rotate(0deg) translate(0, -130%);
    }

    &.bar2 {
      animation-delay: -0.9167s;
      transform: rotate(30deg) translate(0, -130%);
    }

    &.bar3 {
      animation-delay: -0.833s;
      transform: rotate(60deg) translate(0, -130%);
    }

    &.bar4 {
      animation-delay: -0.7497s;
      transform: rotate(90deg) translate(0, -130%);
    }

    &.bar5 {
      animation-delay: -0.667s;
      transform: rotate(120deg) translate(0, -130%);
    }

    &.bar6 {
      animation-delay: -0.5837s;
      transform: rotate(150deg) translate(0, -130%);
    }

    &.bar7 {
      animation-delay: -0.5s;
      transform: rotate(180deg) translate(0, -130%);
    }

    &.bar8 {
      animation-delay: -0.4167s;
      transform: rotate(210deg) translate(0, -130%);
    }

    &.bar9 {
      animation-delay: -0.333s;
      transform: rotate(240deg) translate(0, -130%);
    }

    &.bar10 {
      animation-delay: -0.2497s;
      transform: rotate(270deg) translate(0, -130%);
    }

    &.bar11 {
      animation-delay: -0.167s;
      transform: rotate(300deg) translate(0, -130%);
    }

    &.bar12 {
      animation-delay: -0.0833s;
      transform: rotate(330deg) translate(0, -130%);
    }
  }
}

@keyframes fade {
  from {
    opacity: 1;
    }
  to {
    opacity: 0.25;
    }
}
</style>
