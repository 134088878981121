<template>
  <div
    v-b-tooltip.hover.right
    class="tooltip__container"
    :title="text"
  >
    ?
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .tooltip {
    .b-tooltip {
      opacity: 1;
    }

    &-inner {
      background: #336699;
      color: #fff;
    }

    &__container {
      background-color: #999;
      border-radius: 2px;
      color: #333;
      cursor: default;
      font-size: 10px;
      height: 12px;
      line-height: 12px;
      text-align: center;
      width: 12px;
    }
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #336699;
  }
</style>
